import Link from 'next/link';
import { useState } from 'react';
import { useModalsStore } from 'store/modals';
import { useAuthStore } from 'store/auth';
import { MobileMenu } from './MobileMenu';
import { Explore } from 'components/icons/Explore';
import { Inbox } from 'components/icons/Inbox';
import { Create } from 'components/icons/Create';
import { Profile } from 'components/icons/Profile';
import { Login } from 'components/icons/Login';
import { Hamburger } from 'components/icons/Hamburger';

export const MobileNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user, isLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const nav = [
    { id: 1, path: '/', icon: <Explore className="size-7" /> },
    { id: 2, path: '/chat', icon: <Inbox className="size-7" /> },
    isLogged
      ? { id: 3, path: '/my-crushes', icon: <Create className="size-7" /> }
      : { id: 3, path: '/create', icon: <Create className="size-7" /> },
    {
      id: 4,
      path: '/settings',
      icon: user ? (
        <Profile className="size-7" />
      ) : (
        <Login className="size-7" onClick={() => setIsOpenAuthModal(true)} />
      )
    },
    {
      id: 5,
      path: '/#',
      icon: <Hamburger className="size-7" />,
      onClick: (e: any) => {
        e.preventDefault();
        setIsMenuOpen(true);
      }
    }
  ];

  return (
    <>
      <div
        id="mobileMenu"
        className={`lg:hidden fixed left-0 bottom-0 right-0 border-t border-primary bg-primary py-3 z-30`}
      >
        <nav className={`grid grid-cols-5 gap-4`}>
          {nav.map(({ id, path, icon, onClick }) => (
            <Link
              key={id}
              href={path}
              className="flex items-center justify-center w-full"
              onClick={onClick}
              prefetch={false}
            >
              {icon}
            </Link>
          ))}
        </nav>
      </div>
      <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};
