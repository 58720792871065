import React, { useEffect } from 'react';
import Head from 'next/head';
import Bugsnag from 'utils/bugsang';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';

import { ScreenLayout } from 'components/shells/ScreenLayout';
import { reactQuery } from 'lib/reactQuery';
import 'styles/globals.scss';
import { useComputeRefer } from 'hooks/useComputeRefer';
import { useRouter } from 'next/router';
import { setStorageItem } from '../utils/storage';
import { useSocketChat } from 'hooks/useSocketChat';
import { useAuthStore } from 'store/auth';
import { noIndexPages } from 'utils/hardcoded-data';
import { customTokenSignIn } from 'api/auth';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import Script from 'next/script';

const isAppMobile = isCrushAppMobile();

// const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || (({ children }) => children);

const SocketLogic = () => {
  useSocketChat();
  return <></>;
};

const Root = (props: AppProps) => {
  const { query, pathname, push, replace } = useRouter();

  const { user } = useAuthStore();

  useComputeRefer();

  useEffect(() => {
    if (query.utm_source && query.utm_source === 'ja' && query.jaid) setStorageItem('jaid', query.jaid);
    if (query.t) customTokenSignIn(query.t as string).then(() => {
      const url = new URL(window.location.href);
      url.searchParams.delete('t');
      window.history.replaceState(null, '', url.toString());
    });
  }, [query]);

  useEffect(() => {
    if (window !== undefined && window.crush) {
      window.crush.handleSwipeLeft = function () {
        window.history.back();
      };
    }
  }, []);

  return (
    <>
    {/* <ErrorBoundary> */}
      <Head>
        {isAppMobile ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
          />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        )}

        <title>Crush Companion - AI Girlfriend & Companion for NSFW Roleplay</title>
        <meta name="theme-color" content="#1f1f1f" />
        <meta name="title" content="Crush Companion - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          name="description"
          content="Experience the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <link rel="shortcut icon" href="/favicon.ico" />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.crush.to/" />
        <meta property="og:title" content="Crush Companion - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          property="og:description"
          content="Experience the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <meta property="og:image" content="/images/crush-banner-1200x630.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.crush.to/" />
        <meta property="twitter:title" content="Crush Companion - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          property="twitter:description"
          content="Experience the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <meta property="twitter:image" content="/images/crush-banner-1200x675.jpg" />

        {/* SEO */}
        <meta
          name="keywords"
          content="AI NSFW, AI Girlfriend, AI Companion, NSFW Chatbot, Free AI Chatbot, Free AI, NSFW Image Generator, NSFW AI Chatbot, Free NSFW, ERP, erotic roleplay"
        />

        {noIndexPages.some((path) => pathname.includes(path)) ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}

        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Crush" />

        {/* PWA */}
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-title" content="Crush" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      </Head>
      <QueryClientProvider client={reactQuery}>
        {user && <SocketLogic />}

        <Script
          id="google-analytics"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_MEASUREMENT_ID}');
          ${process.env.VERCEL_ENV === 'production' ? `gtag('config', '${process.env.NEXT_PUBLIC_ADWORDS_ID}');` : ''}`
          }}
        />

        <Script
          id="posthog"
          data-turbo-eval="false"
          dangerouslySetInnerHTML={{
            __html: `
        !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        posthog.init('${process.env.NEXT_PUBLIC_POSTHOG_KEY}',{api_host:'https://app.posthog.com',autocapture: false})
        `
          }}
        />

        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`} />

        <ScreenLayout {...props} />
        <ToastContainer
          position="bottom-center"
          theme="dark"
          autoClose={3000}
          closeOnClick
          className="custom-toast-container"
          closeButton={false}
          limit={3}
        />
      </QueryClientProvider>
    {/* </ErrorBoundary> */}
    </>
  );
};

export default Root;
